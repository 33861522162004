import React from "react";


const Footer = () => {
    return (
        <div className="footer">
            <p style={{
                padding: "3%",
                textAlign: "center",
            }}>
             For ideas and feedback please reach me out via email: iakimchuk.a@gmail.com </p>
        </div>
    );
};
export default Footer;